import React, { useRef } from 'react';
import "./HomePage.css";

import { Route, Routes } from "react-router-dom"

import Header from "../components/Header/Header.js";
import Footer from "../components/Footer/Footer.js";

import SiteInfo from "../components/SiteInfo/SiteInfo.js";



import { Widget } from '@typeform/embed-react';


function CVReviewPage(props) {

const widgetContainerStyle = {
    width: '70%',
    height: 300,
    margin: '20px auto',
    opacity: '98%',
  }

    return (

        <div className="HomePage">

            <div className="Header">
                <Header />
            </div>


            <div className="ContentSectionInner">
                <br></br><br></br><br></br>
                <h2>Free CV Review Service for Job Seekers!</h2>
                <br></br><br></br>
                This CV review service is completely free and its purpose is to provide a quick feedback on your CV. We do not provide a professional CV writing service.
                <br></br><br></br>
                We spend approximately 15-30 minutes on each CV and provide our feedback as 3-5 bullet points. You can think it as another pair of eyes on your CV to detect most salient problems.
                <br></br><br></br>    
                Before uploading your CV please make sure that you remove/mask critical information such as adresss, phone number, surname, names of your references, certificate numbers etc.
                <br></br><br></br>

            </div>        


            <div className="ContentSection">

                <div className="flex_row">


                <Widget
                  id='P8T5y3S9'
                  style={widgetContainerStyle}
            
                />
 
                
                </div>
            </div>



            <div className="BottomSiteInfoSection">
            
            </div>

            
            <div className="Footer">
                <Footer />
            </div>

        
        </div>

    )

}


export default CVReviewPage;