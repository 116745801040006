import React, { useRef } from 'react'; 
import './SiteInfo.css';

import { useContext } from 'react';
import { SiteContext } from '../../Main';


function SiteInfo(props)  {

const { siteName, siteTitle, siteSubTitle } = useContext(SiteContext);


	return (

		<div className="SiteInfo">

            <div className="WebSiteName">
              	{siteName}
            </div>

            {/*
            <div className="WebSiteUrl">
            	www.fairhire.me
            </div>
            */ }


            <div className="CatchWord">
            	{siteTitle}
            </div>

            <div className="SubCatchWord">
            	{siteSubTitle}
            </div>            


		</div>

	);



}




export default SiteInfo;