import React from "react";
import {useState, useEffect } from 'react';

import "./List.css";
import Accordion from "./Accordion";
import QuestionBox from "./QuestionBox";

import { useContext } from 'react';
import { SiteContext } from '../../Main';

const questions = [
  {
    questionText: "Is the end to end  process clear to the customer?",
    boolean: false,
  },
  {
    questionText: "Lacus luctus accumsan tortor posuere? ",
    boolean: true,
  },
  {
    questionText:
      "Sed felis eget velit aliquet sagittis id consectetur purus ut? ",
    boolean: false,
  },
  {
    questionText: "Feugiat vivamus at augue eget arcu dictum varius duis at. ",
    boolean: true,
  },
  {
    questionText: "Commodo sed egesta phasellus faucibus scelerisque eleifend?",
    boolean: false,
  },
  {
    questionText: "Commodo sed egesta phasellus faucibus scelerisque eleifend?",
    boolean: false,
  },
  {
    questionText: "Commodo sed egesta phasellus faucibus scelerisque eleifend?",
    boolean: false,
  },
];

const  bgGradients = [
      "linear-gradient(224.37deg, #DFFF00 7.76%, rgba(223, 29, 255, 0.494792) 53.88%, E9967A 100%)",
      "linear-gradient(224.37deg, #CCCCFF 7.76%, rgba(223, 29, 255, 0.494792) 53.88%, #2143F2 100%)",
      "linear-gradient(224.37deg, #FFBF00 7.76%, rgba(223, 29, 219, 0.494792) 53.88%, #2143F2 100%)",
      "linear-gradient(224.37deg, #DE3163 7.76%, rgba(255, 219, 28, 0.494792) 53.88%, #B5C1FF 100%)",
      "linear-gradient(224.37deg, #EE0F44 7.76%, rgba(225, 26, 230, 0.494792) 53.88%, #DE3163 100%)",
      "linear-gradient(224.37deg, #6495ED 7.76%, rgba(196, 255, 30, 0.494792) 53.88%, #2143F2 100%)",
      "linear-gradient(224.37deg, #16FFAB 7.76%, rgba(35, 255, 229, 0.494792) 53.88%, #EED80F 100%)",
      "linear-gradient(224.37deg, #12C6FF 7.76%, rgba(27, 49, 255, 0.494792) 53.88%, #2143F2 100%)",
      "linear-gradient(224.37deg, #EED80F 7.76%, rgba(255, 85, 32, 0.494792) 53.88%, #2143F2 100%)",
      "linear-gradient(224.37deg, #7E1AFF 7.76%, rgba(223, 29, 255, 0.494792) 53.88%, #2143F2 100%)",
      "linear-gradient(224.37deg, #FF2E00 7.76%, rgba(255, 219, 28, 0.494792) 53.88%, #B5C1FF 100%)",
      "linear-gradient(224.37deg, #1F618D 7.76%, rgba(223, 29, 255, 0.494792) 53.88%, E9967A 100%)",
      "linear-gradient(224.37deg, #7E1AFF 7.76%, rgba(223, 29, 255, 0.494792) 53.88%, #2143F2 100%)",
      "linear-gradient(224.37deg, #E9967A 7.76%, rgba(223, 29, 219, 0.494792) 53.88%, #2143F2 100%)",
      "linear-gradient(224.37deg, #12C6FF 7.76%, rgba(223, 29, 255, 0.494792) 53.88%, #EED80F 100%)",
      "linear-gradient(224.37deg, #FFFE10 7.76%, rgba(223, 29, 229, 0.494792) 53.88%, #6495ED 100%)"
]


function List() {

const { searchTerm } = useContext(SiteContext);
const [listData, setListData] = useState([]);

useEffect(() => {

  console.log('useEffect called')

  var searchKey =""
  if (searchTerm!== undefined ) {
    searchKey = searchTerm;
  }

fetch('https://reverse-proxy-http-6qna7cyf7q-ew.a.run.app?key=3680e55eg1q1b676d8645dbbaf0811acd47gfb&service=fhg'+'&search='+searchKey)
.then(response => response.json())
.then(data => {
    console.log('API returns:');
    console.log(data);
    data.map(ent => {
        ent.bgGradient=bgGradients[Math.floor(Math.random() * 15)]; 

        if (ent!== undefined && ent.name!== undefined) {
            ent.initial = ent.name.charAt(0);
        }
    })
    setListData(data);
})
.catch(error => console.log(error));

}, [searchTerm]);


  
  return (
    <SiteContext.Provider value={{ searchTerm}}>
    <div className="List">
      <div className="accordion-container">
        {listData.map(
          (listEntry) => {
            return (
              <Accordion
                key={listEntry.id}
                initial={listEntry.initial}
                bgGradient={bgGradients[(Math.floor(Math.random() * 15+listEntry.overallRating*5))%15+1]}
                title={listEntry.name}
                url={listEntry.url}
                rating={listEntry.overallRating*5}
              >
                <div className="accordionContentContainer">

                      <QuestionBox
                        questionText="Is the recruitment process clear to the applicant?"
                        boolean={Boolean(listEntry.overallRatingValues.c1)}
                        key={"1"}
                        index={"1"}
                      />
                      <QuestionBox
                        questionText="Is the application acknowledged by the company?"
                        boolean={Boolean(listEntry.overallRatingValues.c2)}
                        key={"2"}
                        index={"2"}
                      />
                      <QuestionBox
                        questionText="Is the applicant informed about when to expect a response?"
                        boolean={Boolean(listEntry.overallRatingValues.c3)}
                        key={"3"}
                        index={"3"}
                      />
                      <QuestionBox
                        questionText="Is the applicant notified about the result in case of rejection within reasonable time (Maximum 2 months)?"
                        boolean={Boolean(listEntry.overallRatingValues.c4)}
                        key={"4"}
                        index={"4"}
                      />
                       <QuestionBox
                        questionText="Is the applicant given a rough idea about the rejection reason?"
                        boolean={Boolean(listEntry.overallRatingValues.c5)}
                        key={"5"}
                        index={"5"}
                      />
                </div>
              </Accordion>
            );
          }
        )}
      </div>
    </div>
    </SiteContext.Provider>
  );
}

export default List;


{ /*

function List() {

  const { listData } = useContext(SiteContext);
  return (
    <div className="List">
      <div className="accordion-container">
        {accordions.map(
          ({ initial, bgGradient, title, url, rating }, index) => {
            return (
              <Accordion
                key={index}
                initial={initial}
                bgGradient={bgGradient}
                title={title}
                url={url}
                rating={rating}
              >
                <div className="accordionContentContainer">
                  {questions.map(({ questionText, boolean }, index) => {
                    return (
                      <QuestionBox
                        questionText={questionText}
                        boolean={boolean}
                        key={index}
                        index={index}
                      />
                    );
                  })}
                </div>
              </Accordion>
            );
          }
        )}
      </div>
    </div>
  );
}


*/}
