import React, { useState } from "react";
import styles from "./jobboard.module.css";
import JobBoardColumn from "./JobBoardColumn";


let topics = ["Worldwide", "Freelancing", "Remote", "Germany", "Switzerland"];

let data = [
  {
    topic:"Worldwide",
    links: [
      "indeed.com",
      "linkedin.com",
      "monster.com",
      "careerjet.com",
      "otta.com",
      "simplyhired.com",
      "jobrapido.com",
      "flexjobs.com",
      "internationaljobs.com",
      "eu-startups.com",
      "ceo-worldwide.com"
    ],
  },
  {
    topic:"Remote & Freelancing",
    links: [
      "remote.co",
      "remoteok.io",
      "remotive.io",
      "weworkremotely.com",
      "euremotejobs.com",
      "remoters.net",
      "fiverr.com",
      "freelancer.com",
      "upwork.com",
      "www.guru.com",
      "solidgigs.com"
    ],
  },
  {
    topic:"Germany",
    links: [
      "stepstone.de",
      "jobvector.de",
      "make-it-in-germany.com",
      "careerjobs.de",
      "glassdoor.de",
      "germantechjobs.de",
      "academics.com",
      "englishjobs.de",
      "indeed.de",
      "germanystartupjobs.com",
      "kimeta.de"
    ],
  },
  {
    topic:"Switzerland",
    links: [
      "jobs.ch",
      "jobagent.ch",
      "indeed.ch",
      "englishjobsearch.ch",
      "neuvoo.ch",
      "jobmarket.ch",
      "job-room.ch",
      "jobscout24.ch",
      "swissdevjobs.ch",
      "jobup.ch",
      "alpha.ch",
      "neuvoo.ch"
    ],
  },
];

export default function Footer() {
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <div className={styles.footer}>
      <h2 className={styles.topHeading}>Job Boards</h2>
      <div className={styles.footerColumnContainer}>
        {data.map((obj, ind) => {
          return <JobBoardColumn key={ind} index={ind + 1} links={obj.links}/>;
        })}
      </div>
      <div className={styles.linkContainer}>
      </div>
    </div>
  );
}
