import React, { useRef } from 'react'; 
import './ActionBlock.css';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';

import { useNavigate } from "react-router-dom";


function ActionBlock(props)  {


  let navigate = useNavigate(); 

  const handleButtonClickAddRating = () =>{ 
  	console.log("handleButtonClickAddRating");
    let path = `/addrating`; 
    navigate(path);
  }

  const handleButtonClickCVReview = () =>{ 
    console.log("handleButtonClickCVReview");
    let path = `/cvreview`; 
    navigate(path);
  }

  const handleButtonClickJobAddReview = () =>{ 
    console.log("handleButtonClickJobAddReview");
    let path = `/jobadreview`; 
    navigate(path);
  }
  
  
  


	return (

		<div className="topInfoBlock">
          
          <div>
      			<Button size="small" onClick={handleButtonClickAddRating} style={{ fontSize: "0.9rem" , backgroundColor: "#E86850"}} variant="contained" startIcon={<AddCircleIcon />}> Add Rating</Button>
      	  </div>

      	  <div>
            	<Button size="small" onClick={handleButtonClickCVReview} style={{ fontSize: "0.9rem" , backgroundColor: "#21b6ae"}} variant="contained" startIcon={<DescriptionIcon />}> Free CV Review</Button>
          </div>

           <div>
           	 	<Button size="small" onClick={handleButtonClickJobAddReview} style={{ fontSize: "0.9rem" , backgroundColor: "#587498"}} variant="contained" startIcon={<BookmarkAddedIcon />}> Free Job Ad Review</Button>
           </div>

		</div>

	);



}




export default ActionBlock;