import React from "react";

import { Link, useMatch, useResolvedPath } from "react-router-dom"

import { useContext } from 'react';
import { SiteContext } from '../../Main';


import "./Navbar.css"

export default function Navbar() {
  const { siteName } = useContext(SiteContext);
  return (
    <nav className="nav">
      <Link to="/" className="site-title">
        { siteName }
      </Link>
      <ul>
        <div className="home"><CustomLink to="/">Home</CustomLink></div>
        <CustomLink to="/about">About</CustomLink>
        <CustomLink to="/jobboards">Job Boards</CustomLink>
        <div className="addrating"><CustomLink to="/addrating">Add Rating</CustomLink></div>
      </ul>
    </nav>
  )
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true })

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}