import React, { useState } from "react";
import styles from "./footer.module.css";
import FooterColumn from "./footerColumn";

let data = [
  {
    topic:"Worldwide",
    links: [
      "indeed.com",
      "linkedin.com",
      "monster.com",
      "careerjet.com"
    ],
  },
  {
    topic:"Remote & Freelancing",
    links: [
      "remote.co",
      "remoteok.io",
      "weworkremotely.com",
      "fiverr.com"
    ],
  },
  {
    topic:"Germany",
    links: [
      "stepstone.de",
      "jobvector.de",
      "make-it-in-germany.com",
      "careerjobs.de"
    ],
  },
  {
    topic:"Switzerland",
    links: [
      "jobs.ch",
      "jobagent.ch",
      "indeed.ch",
      "swissdevjobs.ch"
    ],
  },
];

export default function Footer() {
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <div className={styles.footer}>
      <h2 className={styles.topHeading}>Job Boards</h2>
      <div className={styles.footerColumnContainer}>
        {data.map((obj, ind) => {
          return <FooterColumn key={ind} index={ind + 1} links={obj.links} />;
        })}
      </div>
      <div className={styles.linkContainer}>
        <a href={"/jobboards"}>See more resources</a>
      </div>
    </div>
  );
}
