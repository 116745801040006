import React, { useRef, createContext, useState, useEffect } from 'react';
import "./Main.css";

import { Route, Routes } from "react-router-dom"




import Navbar from "./components/Navbar/Navbar"
import HomePage from "./Pages/HomePage"
import AboutPage from "./Pages/AboutPage"
import JobBoardsPage from "./Pages/JobBoardsPage"
import AddRatingPage from "./Pages/AddRatingPage"
import CVReviewPage from "./Pages/CVReviewPage"
import JobAdReviewPage from "./Pages/JobAdReviewPage"

export const SiteContext = createContext();


function Main(props) {

const [siteName, setSiteName] = useState('FairHire.Me');
const [siteURL, setSiteURL] = useState('www.fairhire.Me');
const [siteTitle, setSiteTitle] = useState('Rating database for hiring processes of organisations!');
const [siteSubTitle, setSiteSubTitle] = useState('When you applied to a job, how clear and fair was the hiring process? Share your experience with us!');
const [contactEmail, setContactEmail] = useState('contact@mywebsite.com');
const [apiData, setApiData] = useState();
const [searchTerm, setSearchTerm] = useState();
const [homePageAboutText, setHomePageAboutText] = useState(`
        <br></br>
        <b>UGLY REALITY OF THE JOB SEARCH PROCESS!</b>
        <br></br><br></br>
        You read through hundreds of job ads and finally find one that really suits you. Then you update your CV, research the company, write a cover letter, answer various technical and non-technical questions during the application. 
        <br></br><br></br>
        Then they ask you to record a video of yourself answering some questions, you take a personality test and provide many documents (diploma, certificates, work references, portfolio of past work etc.). 
        <br></br><br></br>
        You spend several hours, maybe a whole day for a single application and then you hear nothing from the job poster. No email, no phone call, no acceptance, no rejection, no news! Nothing! Does it sound familiar to you? 
        <br></br><br></br>
        This is the ugly reality of the job search process.
        <br></br><br></br>
        But this should change! We would like to improve the hiring process. Hiring process should be fair to the candidate, hiring manager and the employer. This is the main idea behind <b>fairhire.me</b>.
        <br></br><br></br>
        Please share your job application experience with us! This will not only make the hiring process more transparent and predictable for other candidates but also will give the hiring managers and employers some chance to improve their hiring processes.
        <br></br><br></br>
        Please share your both positive and negative experiences and do it honestly. Do something good for society!

        <br></br><br></br><br></br>
        <b>Note to Employers and Hiring Managers:</b> If you think that information given in our web-site does not reflect the reality due to any reason (maybe your hiring process has changed during the years, maybe there was a one-off case which was not handled properly, maybe an unhappy candidate rated your hiring process unfairly) please let us know and tell us more about your hiring practices. We are going to publish your hiring practices, we are going to scrutinize the ratings given for your company and take corrective actions if needed.
        
    `);


return (

<SiteContext.Provider value={{ siteName, siteURL, siteTitle, siteSubTitle, contactEmail, homePageAboutText, apiData, searchTerm, setSearchTerm}}>
    <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/jobboards" element={<JobBoardsPage />} />
          <Route path="/addrating" element={<AddRatingPage />} />
          <Route path="/cvreview" element={<CVReviewPage />} />
          <Route path="/jobadreview" element={<JobAdReviewPage />} />
        </Routes>
    </div>
</SiteContext.Provider>

    );

}


export default Main;



{ /*

import React, { useContext } from 'react';
import { SiteContext } from '../../App';
const { siteName } = useContext(SiteContext);
 
 */}