import React from "react";
import ReactHtmlParser from "react-html-parser";

import "./AboutInfo.css";

import { useContext } from 'react';
import { SiteContext } from '../../Main';



function AboutInfo() {

  const { homePageAboutText } = useContext(SiteContext);


  return (
    <div className="AboutInfo">
      <div className="TextArea2Inner">  
        
        <div style={{ textAlign: 'left', color: 'white'}}>

            {ReactHtmlParser(homePageAboutText)}
        
        </div>
      </div>
    </div>
  );
}

export default AboutInfo;
