import React, { useRef } from 'react';
import "./HomePage.css";

import { Route, Routes } from "react-router-dom"

import Header from "../components/Header/Header.js";
import Footer from "../components/Footer/Footer.js";

import SiteInfo from "../components/SiteInfo/SiteInfo.js";
import AboutInfo from "../components/AboutInfo/AboutInfo.js";



function AboutPage(props) {

    return (

        <div className="HomePage">

            <div className="Header">
                <Header />
            </div>

            <div className="TopSiteInfoSection">
                <SiteInfo />
            </div>


            <div className="ContentSection">
                <AboutInfo />
            </div>

        
            
            <div className="Footer">
                <Footer />
            </div>

        
        </div>

    )

}


export default AboutPage;