import React from "react";
import ReactHtmlParser from "react-html-parser";

import { useContext } from 'react';
import { SiteContext } from '../../Main';

import "./TextArea.css";



function TextArea() {

const { homePageAboutText } = useContext(SiteContext);

  return (
    <div className="TextArea">
      <div className="TextAreaInner">  
        
        <div style={{ textAlign: 'left', color: 'white'}}>

            {ReactHtmlParser(homePageAboutText)}

        </div>
      </div>
    </div>
  );
}

export default TextArea;
