import React, { useState } from "react";
import ContactPopup from "./contactPopup";
import styles from "./footer.module.css";
import FooterColumn from "./footerColumn";


export default function Footer() {
  const [popupOpen, setPopupOpen] = useState(false);

  return (
    <div className={styles.footer}>
      <div className={styles.seperator} />
      <div className={styles.copyRightContainer}>
        <p>Copyright © 2022 - All Rights Reserved.</p>
        <div className={styles.privacyPolicy}>
          <a href={"#"}>Privacy Policy</a>
          
          <a>
            <span onClick={() => setPopupOpen(!popupOpen)}> Contact </span>
            <ContactPopup boolean={popupOpen} />
          </a>
        </div>
      </div>
    </div>
  );
}
