import React, { useRef } from 'react';
import "./HomePage.css";

import { Route, Routes } from "react-router-dom"

import Header from "../components/Header/Header.js";
import Footer from "../components/Footer/Footer.js";

import SiteInfo from "../components/SiteInfo/SiteInfo.js";
import SearchBox from "../components/SearchBox/SearchBox.js";
import ActionBlock from "../components/ActionBlock/ActionBlock.js";
import JobBoards from "../components/JobBoards/JobBoards.js";
import List from "../components/List/List.js";
import TextArea from "../components/TextArea/TextArea.js";



function HomePage(props) {

    return (

        <div className="HomePage">

            <div className="Header">
                <Header />
            </div>

            <div className="TopSiteInfoSection">
                <SiteInfo />
            </div>

             <div className="TopContentSection">
                    <div>
                        <SearchBox />
                        <ActionBlock />
                    </div>
            </div>


            <div className="ContentSection">
                <div className="flex_row">

                    <div className="LeftContentSection">
                         <List />
                    </div>
                
                    <div className="RightContentSection">
                        <TextArea />
                    </div>
                
                </div>
            </div>

            <div className="BottomContentSection">
                <JobBoards />
            </div>


            
            <div className="Footer">
                <Footer />
            </div>

        
        </div>

    )

}


export default HomePage;