import React from "react";
import styles from "./jobboard.module.css";


export default function JobBoardColumn({ links, index }) {

  let topics = ["Worldwide", "Remote & Freelancing", "Germany", "Switzerland"];

  return (
    <div className={styles.footerColumn}>
      <ul>
        <h2>{topics[index-1]}</h2>

        {links.map((link, ind) => {
         
          return <li><a href={'https://' +link} target="_blank" className={styles.footerColumn}>{link}</a></li>;
        })}
      </ul>
    </div>
  );
}
