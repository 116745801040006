import React from "react";


import Navbar from "../../components/Navbar/Navbar"


export default function Header() {
  
  /*return <h1>PLACE HOLDER COMPONENT HeaderSection</h1>;*/

    return (
    <>
      <Navbar />

    </>
  );
}
