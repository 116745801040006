import React from "react";
import styles from "./popup.module.css";
import contactImage from "./contact.png";

export default function ContactPopup({ boolean }) {
  return (
    <div className={styles.popup} aria-role={boolean}>
      <p className={styles.contactHeading}>Contact us at</p>
       <p className={styles.email}>contact@fairhire.me</p>   
      {/* <img className={styles.email} src={contactImage} alt="info"></img> */}
    </div>
  );
}
