import React, { useRef } from 'react'; 
import {createContext, useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';

import { SiteContext } from '../../Main';


function SearchBox(props)  {
 

    const { setSearchTerm } = useContext(SiteContext);

    const searchFieldRef = useRef();

    const handleClick = () => {
      console.log("handleClick");
      var searchQuery = searchFieldRef.current.getElementsByTagName("input")[0].value;
      setSearchTerm(searchQuery);
      printSearchTerm(searchQuery);
    }

    const handleButtonClick = () => {
      console.log("handleButtonClick");
    }

    const handleKeyPress = (event) => {
      if(event.key=='Enter') {
        console.log("handleKeyPress: "+event.key);
        var searchQuery = searchFieldRef.current.getElementsByTagName("input")[0].value;
        setSearchTerm(searchQuery);
        printSearchTerm(searchQuery);
      }

    }

    const handleChange = (event) => {
    // Get input value from "event"
    console.log("handleChange");
     var searchQuery = searchFieldRef.current.getElementsByTagName("input")[0].value;

    if (searchQuery==''){
        setSearchTerm(searchQuery);
    }
  }

    function printSearchTerm(searchQuery){
      console.log(searchQuery);

    }

    

 return ( 


  <Box
      sx={{
        borderRadius: "20px",
        input: {textAlign: "center"},
        margin: "0 auto",
        width: "70vw"
      }}
    >

     <TextField fullWidth style={{ backgroundColor: "white", borderRadius: "20px" }} ref={searchFieldRef} onKeyPress={(e) => handleKeyPress(e)}  onChange={handleChange} 
        hiddenLabel
        id="filled-hidden-label-small"
        placeholder="search organisations" 
        variant="outlined"
        size="large"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end"  onClick={handleClick} >
              <SearchIcon />
            </InputAdornment>
          ),
          
        }}
      />
      </Box>
   


    );
  
}


export default SearchBox;






