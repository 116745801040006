import React, { useRef } from 'react';
import "./HomePage.css";

import { Route, Routes } from "react-router-dom"

import Header from "../components/Header/Header.js";
import Footer from "../components/Footer/Footer.js";

import SiteInfo from "../components/SiteInfo/SiteInfo.js";



import { Widget } from '@typeform/embed-react';


function AddRatingPage(props) {

const widgetContainerStyle = {
    width: '70%',
    height: 400,
    margin: '20px auto',
    opacity: '98%',
  }

    return (

        <div className="HomePage">

            <div className="Header">
                <Header />
            </div>

            <div className="TopSiteInfoSection">
                <SiteInfo />
            </div>


            <div className="ContentSection">
                <div className="flex_row">


                <Widget
                  id='a5Tke8uV'
                  style={widgetContainerStyle}
                  medium="demo-test"
                  hidden={{ foo: 'foo value', bar: 'bar value' }}
                  transitiveSearchParams={['foo', 'bar']}
                  iframeProps={{ title: 'Foo Bar' }}
                />
 
                
                </div>
            </div>



            <div className="BottomSiteInfoSection">
            
            </div>

            
            <div className="Footer">
                <Footer />
            </div>

        
        </div>

    )

}


export default AddRatingPage;