import React, { useRef } from 'react';
import "./HomePage.css";

import { Route, Routes } from "react-router-dom"

import Header from "../components/Header/Header.js";
import Footer from "../components/Footer/Footer.js";

import SiteInfo from "../components/SiteInfo/SiteInfo.js";



import { Widget } from '@typeform/embed-react';


function JobAdReviewPage(props) {

const widgetContainerStyle = {
    width: '70%',
    height: 300,
    margin: '20px auto',
    opacity: '98%',
  }

    return (

        <div className="HomePage">

            <div className="Header">
                <Header />
            </div>


            <div className="ContentSectionInner">
                <br></br><br></br><br></br>
                <h2>Free Job Ad Review Service for Small and Medium Enterprises!</h2>
                <br></br><br></br><br></br>
                This job ad review service is completely free and its purpose is to provide a quick feedback on your job advertisement. Clear, precise, carefully written job ads are vital for a proper hiring process. Quality of the job ad is important for both candidates and hiring managers.
                <br></br><br></br><br></br>
                We spend approximately 1 hour on each job ad and provide our feedback as 3-10 bullet points. You can think it as another pair of eyes on your job ad to increase clarity, to detect most salient problems and to precisely target the right skill set.
                <br></br><br></br><br></br>
            </div>        


            <div className="ContentSection">

                <div className="flex_row">


                <Widget
                  id='kzv1Jg0P'
                  style={widgetContainerStyle}
            
                />
 
                
                </div>
            </div>



            <div className="BottomSiteInfoSection">
            
            </div>

            
            <div className="Footer">
                <Footer />
            </div>

        
        </div>

    )

}


export default JobAdReviewPage;